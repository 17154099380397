body, html, #my-root, #global_wrapper, #global_content {
    height: 100vh;
    width: 100%;
}
body {
    overflow: hidden;
}
.nonlogged-in body,
.layout_page_downloads body {
    overflow-y: auto;
}

.business_account header,
.hide_header_business_account header,
.business_background_photo .bg_slider,
.hide_business_background_photo .bg_slider{
    display: none;
}
html.boxed_style #my-root {
    max-width: 1200px;
    width: 100%;
    margin: 0px auto;
}
html.boxed_style #my-root>div::before {
    max-width: 1200px;
    left: auto;
}
html.boxed_style .business-bg-slider,
html.boxed_style .business-legal-header {
    max-width: 1200px;
}
html.boxed_style .background-slider,
html.boxed_style #my-root header {
    max-width: 1200px;
    left: 0px;
    right: 0px;
    margin: 0px auto;
}
.App {
  text-align: center;
  height: 100%;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}
html.layout_page_downloads,
html.layout_page_home,
html.layout_page_signup,
html.layout_page_auth_login {
    #global_content {
        footer {
            display: none !important;
        }
    }
}
.sd_loader {
    width: 30px;
    height: 30px;
    margin: 0px auto;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    &.align_left {
        margin-left: 0px;
        text-align: left;
        justify-content: flex-start;
    }
    &.align_right {
        margin-right: 0px;
        text-align: right;
        justify-content: flex-end;
    }
}

.jodit_tooltip {
    padding: 0 !important
}
.transfer_loader {
    position: relative;
    & span {
        position: absolute;
        left: 0px;
        right: 0px;
        top: 0px;
        bottom: 0px;
        height: 60px;
        width: 60px;
        margin: auto;
        text-align: center;
        line-height: 60px;
        font-size: 25px;
        font-weight: bold;
    }
}
.transfer_loader svg {
    -webkit-animation: loader--rotate 2s linear infinite;
    animation: loader--rotate 2s linear infinite;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
}
.transfer_loader .loader_foreground {
    stroke-dasharray: 502.655;
    stroke-dashoffset: 0;
    stroke-width: 10;
    stroke: rgb(232, 235, 237)
}
.transfer_loader .loader_foreground {
    border-radius: 10px;
    stroke-linecap: round;
    -webkit-transition: stroke-dashoffset 200ms linear, stroke 200ms linear;
    transition: stroke-dashoffset 200ms linear, stroke 200ms linear;
}
.MuiDialog-scrollPaper {
    flex-direction: column;
    height: auto !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.passwordCheck-valid-customizable {
	color: #19BF00;	
	font-size: 14px;
    text-align: left;
}
.passwordCheck-notValid-customizable {
	color: #DF3312;	
    font-size: 14px;
    text-align: left;
}
.passwordCheck-valid-customizable .validation-error-symbol,
.passwordCheck-notValid-customizable .validation-error-symbol {
	margin-right: 5px;
}
.layout_page_user_links .banner-text-content,
.layout_page_user_home .banner-text-content,
.layout_page_user_shared .banner-text-content,
.layout_page_user_favourites .banner-text-content,
.layout_page_user_archives .banner-text-content,
.layout_page_shared_view .banner-text-content {
    display: none;
}
#freshworks-container iframe {
    bottom: 5px !important;
    right: 15px !important;
}
// #freshworks-container iframe {
//     left: 0px !important;
//     right: auto !important;
// }
// #freshworks-container .frame-right {
//     left: 30px !important;
//     right: auto !important;
// }
// @media only screen and (max-width: 667px){
//     #freshworks-container .frame-right {
//         left: 0px !important;
//     }
// }

@media only screen and (max-width: 960px){
    #freshworks-container iframe {
        bottom: auto !important;
        right: 20px !important;
        top: 42px !important;
        z-index: 3 !important;
    }
    #freshworks-container .frame-right {
        right: 28px !important;
        bottom: auto !important;
        top: 90px !important;
        height: calc(100% - 95px) !important;
    }
}
@media only screen and (max-device-width: 667px), screen and (max-width: 450px){
    #freshworks-container #freshworks-frame-wrapper {
        z-index: 2147483001 !important;
        height: 100% !important;
        top: 0px !important;
    }
}

@keyframes loader--rotate {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}